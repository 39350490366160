var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "crumbs" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", [
              _c("i", { staticClass: "el-icon-lx-emoji" }),
              _vm._v(" 自定义图标")
            ])
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "container" }, [
      _c("h2", [_vm._v("使用方法")]),
      _c("p", { staticStyle: { "line-height": "50px" } }, [
        _vm._v(
          "\n            直接通过设置类名为 el-icon-lx-iconName 来使用即可。例如：（共" +
            _vm._s(_vm.iconList.length) +
            "个图标）\n        "
        )
      ]),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("br"),
      _c("h2", [_vm._v("图标")]),
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c("el-input", {
            staticClass: "search",
            attrs: {
              size: "large",
              clearable: "",
              placeholder: "请输入图标名称"
            },
            model: {
              value: _vm.keyword,
              callback: function($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword"
            }
          })
        ],
        1
      ),
      _c(
        "ul",
        _vm._l(_vm.list, function(item, index) {
          return _c("li", { key: index, staticClass: "icon-li" }, [
            _c("div", { staticClass: "icon-li-content" }, [
              _c("i", { class: "el-icon-lx-" + item }),
              _c("span", [_vm._v(_vm._s(item))])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "example-p" }, [
      _c("i", {
        staticClass: "el-icon-lx-redpacket_fill",
        staticStyle: { "font-size": "30px", color: "#ff5900" }
      }),
      _c("span", [_vm._v('<i class="el-icon-lx-redpacket_fill"></i>')])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "example-p" }, [
      _c("i", {
        staticClass: "el-icon-lx-weibo",
        staticStyle: { "font-size": "30px", color: "#fd5656" }
      }),
      _c("span", [_vm._v('<i class="el-icon-lx-weibo"></i>')])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "example-p" }, [
      _c("i", {
        staticClass: "el-icon-lx-emojifill",
        staticStyle: { "font-size": "30px", color: "#ffc300" }
      }),
      _c("span", [_vm._v('<i class="el-icon-lx-emojifill"></i>')])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }